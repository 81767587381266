require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("lazysizes")
require("ekko-lightbox")

import "./bootstrap_custom.js"
import $ from "jquery";
global.$ = jQuery;

import "../stylesheets/showroom"

import "../images/nadine.jpg"
import "../images/footer_icon_facebook.svg"
import "../images/footer_icon_instagram.svg"

document.addEventListener("turbolinks:load", function() {
  document.querySelector("#navbar-toggler").addEventListener("click", function(){
    document.querySelector("body").classList.toggle("with-visible-navbar-collapse");
  });
});

document.addEventListener("turbolinks:click", function() {
  $("#navbarNavDropdown").removeClass("show");
});

$(document).on("click", "[data-toggle='lightbox']", function (event) {
  event.preventDefault();

  if (document.documentElement.clientWidth > 500) {
    $(this).ekkoLightbox();
  } else {
    return false;
  }
});
